import { useLocation } from '@reach/router';
import React, { PropsWithChildren } from 'react';
import { metadata } from '../../data/metadata/pages';
import { SEO } from '../seo';

export const Layout = ({ children }: PropsWithChildren<{}>) => {
  const { pathname } = useLocation();
  const meta = metadata[pathname];

  return (
    <main>
      {process.env.STORYBOOK === 'true' ? null : <SEO {...meta} />}
      {children}
    </main>
  );
};
