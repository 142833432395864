import React from 'react';
import { TitledSectionDesktop } from './desktop';
import { TitledSectionMobile } from './mobile';

export interface TitledSectionProps {
  title: string;
}

export const TitledSection = ({ title, children }: React.PropsWithChildren<TitledSectionProps>) => (
  <React.Fragment>
    <TitledSectionMobile title={title}>{children}</TitledSectionMobile>
    <TitledSectionDesktop title={title}>{children}</TitledSectionDesktop>
  </React.Fragment>
);
