import { OwnUpGridItem } from '@rategravity/own-up-component-library';
import styled from 'styled-components';

export const NumberGridItem = styled(OwnUpGridItem)`
  display: flex;
  justify-content: center;
`;

export const TextGridItem = styled(OwnUpGridItem)`
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0 !important;
`;
