import { MOSS_100, MOSS_50, PropsWithTheme } from '@rategravity/own-up-component-library';
import styled from 'styled-components';

export const Section = styled.section`
  background: ${MOSS_100};
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10, 0, 10)};

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

export const ChildrenWrapper = styled.div`
  background-color: ${MOSS_50};
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10, 10, 10, 1.5)};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5)}px;
  margin: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(-10, -10, -10, 1.5)};

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('md')} {
    margin: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(-10, -6, -10)};
  }
`;
