import { IconMultiple, MoneyIcon, PurchaseIcon, SealIcon } from '@rategravity/marketing-components';
import { OwnUpThemeProvider, ownUpWhiteTheme } from '@rategravity/own-up-component-library';
import React from 'react';

const valueProps = [
  {
    icon: <PurchaseIcon />,
    header: 'All-cash offers win bidding wars',
    body: 'All-cash offers are 4x more likely to be accepted, according to a recent study.'
  },
  {
    icon: <SealIcon />,
    header: 'Waive financing & appraisal contingency with confidence.',
    body: 'If you can’t secure financing by closing, we buy the property and then rent it to you. After you’ve secured financing, you can buy it from us at the same purchase price.'
  },
  {
    icon: <MoneyIcon />,
    header: 'Fees as low as 1%',
    body: 'Get the power of an all-cash offer for 1% of the purchase price.'
  }
];

export const ValueProps = () => (
  <OwnUpThemeProvider theme={ownUpWhiteTheme}>
    <IconMultiple ariaLabel="Value Props" valueProps={valueProps} />
  </OwnUpThemeProvider>
);
