import React from 'react';
import { FrequentlyAskedQuestions } from '../components/faq';
import { Footer } from '../components/footer';
import { Header, HeaderImage } from '../components/header';
import { HowItWorks } from '../components/how-it-works';
import { Layout } from '../components/layout';
import { ValueProps } from '../components/value-props';

export const Home = () => (
  <Layout>
    <Header image={<HeaderImage />} />
    <HowItWorks />
    <ValueProps />
    <FrequentlyAskedQuestions />
    <Footer />
  </Layout>
);

export default Home;
