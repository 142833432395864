import {
  ownUpDarkTheme,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridWrapper,
  OwnUpHeadlineBook,
  OwnUpSmallSubheadlineRegular,
  OwnUpThemeProvider
} from '@rategravity/own-up-component-library';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { ContentWrapper, HeadlineText, ImageWrapper, Section } from './elements';

export const HeaderImage = () => (
  <StaticImage
    src="../../images/couple-in-blue.png"
    alt="couple in blue"
    placeholder="blurred"
    transformOptions={{
      fit: 'contain'
    }}
  />
);

export const Header = ({ image }: { image: JSX.Element }) => (
  <OwnUpThemeProvider theme={ownUpDarkTheme}>
    <Section aria-labelledby="headline">
      <OwnUpGridWrapper>
        <OwnUpGridContainer variant="legacy">
          <OwnUpGridItem xs={12} md={7}>
            <ContentWrapper>
              <div>
                <OwnUpSmallSubheadlineRegular variant="subtitle1">
                  Compete with cash offers. Stand out from the competition.
                </OwnUpSmallSubheadlineRegular>
                <HeadlineText>
                  <OwnUpHeadlineBook variant="h1" id="headline">
                    Become a Power Buyer.
                  </OwnUpHeadlineBook>
                </HeadlineText>
              </div>
            </ContentWrapper>
          </OwnUpGridItem>
          <OwnUpGridItem
            xs={12}
            md={5}
            style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}
          >
            <ImageWrapper>{image}</ImageWrapper>
          </OwnUpGridItem>
        </OwnUpGridContainer>
      </OwnUpGridWrapper>
    </Section>
  </OwnUpThemeProvider>
);
