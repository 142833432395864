import { ALOE_100, PropsWithTheme } from '@rategravity/own-up-component-library';
import styled from 'styled-components';

export const Section = styled.section`
  background-color: ${ALOE_100}; /* fall back color */
  background-color: ${({ theme }: PropsWithTheme<{}>) =>
    theme.backgroundColors.primaryTheme?.backgroundColor};
  color: ${({ theme }: PropsWithTheme<{}>) => theme.palette.text.primary};
  padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
`;

export const ContentWrapper = styled.div`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5, 0)};
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.only('md')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(11, 0)};
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(20, 0)};
  }
  display: flex;
  align-items: center;
`;

export const HeadlineText = styled.div`
  padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px;
`;

export const ImageWrapper = styled.div`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('sm')} {
    justify-content: center;
  }
`;
