interface Metadata {
  title: string;
  description: string;
}

// Keys correspond to pathnames.
export const metadata: Record<string, Metadata> = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '/': {
    title: 'Win against cash offers on houses',
    description: 'Win against cash offers on houses'
  }
};
