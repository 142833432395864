import {
  OwnUpExtraSmallHeadlineBook,
  OwnUpGridWrapper
} from '@rategravity/own-up-component-library';
import React from 'react';
import { TitledSectionProps } from '..';
import { ChildrenWrapper, HeadlineWrapper, Section } from './elements';

export const TitledSectionMobile = ({
  title,
  children
}: React.PropsWithChildren<TitledSectionProps>) => {
  const headlineId = `${title}--section-mobile`;

  return (
    <Section aria-labelledby={headlineId}>
      <HeadlineWrapper>
        <OwnUpGridWrapper>
          <OwnUpExtraSmallHeadlineBook variant="h2" id={headlineId}>
            {title}
          </OwnUpExtraSmallHeadlineBook>
        </OwnUpGridWrapper>
      </HeadlineWrapper>
      <OwnUpGridWrapper>
        <ChildrenWrapper>{children}</ChildrenWrapper>
      </OwnUpGridWrapper>
    </Section>
  );
};
