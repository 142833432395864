export const faqs = [
  {
    question: 'What does the Power Buyer program cost me?',
    answer:
      'The fee to use the Power Buyer program is between 1% and 3% of the purchase price. When you make an offer through the program, you deposit 3.0% of the purchase price within 72 hours of executing the purchase offer. If you secure financing on the closing date from your pre-approved lender, Power Buyer keeps 1% as a fee. If you secure financing from a lender other than the pre-approved lender, Power Buyer keeps 2.0% as a fee. If Power Buyer has to buy the property outright, you pay the full 3%.'
  },
  {
    question:
      'If I cannot secure financing and I choose to rent property from Power Buyer, how is the rent determined?',
    answer:
      'Rent is determined as the fair market value plus estimated costs, and is agreed upon upfront, before the purchase contract is signed, so there are no surprises.'
  },
  {
    question: 'Do I have to rent the house from Power Buyer if I am unable to secure financing?',
    answer:
      'No, you do not. However, if you don’t close on the house, you will lose your 3% deposit, and our team will put the house back on the market.'
  },
  {
    question: 'What if the property fails an inspection?',
    answer:
      'In the event of the property failing inspection(s), the purchase may be cancelled by our team. In the event that happens, you will get your full 3% deposit refunded, and earnest money deposit returned, as subject to the terms of the purchase contract.'
  },
  {
    question: 'How is the max Power Buyer Power Offer determined?',
    answer:
      'We will determine the max amount you are eligible to offer based on your financial profile. Our team will also review the market value of the home you are putting an offer on and will back the financing of the lower amount of either the determined fair market value of the property, or the max offer you are eligible for.'
  }
];
