import {
  OwnUpGridContainer,
  OwnUpLargeBody,
  OwnUpLargeHeadlineMedium
} from '@rategravity/own-up-component-library';
import React from 'react';
import { howItWorksSteps } from '../../data/how-it-works';
import { TitledSection } from '../titled-section';
import { NumberGridItem, TextGridItem } from './elements';

export const HowItWorks = () => (
  <TitledSection title="How it works">
    {howItWorksSteps.map((text, index) => (
      <OwnUpGridContainer variant="legacy" key={index}>
        <NumberGridItem xs={3} md={2}>
          <OwnUpLargeHeadlineMedium variant="h3">{index + 1}</OwnUpLargeHeadlineMedium>
        </NumberGridItem>
        <TextGridItem xs>
          <OwnUpLargeBody variant="body1">{text}</OwnUpLargeBody>
        </TextGridItem>
      </OwnUpGridContainer>
    ))}
  </TitledSection>
);
