import {
  OwnUpBody,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridOffset,
  OwnUpSmallSubheadlineMedium
} from '@rategravity/own-up-component-library';
import React from 'react';
import { faqs } from '../../data/faqs';
import { TitledSection } from '../titled-section';
import { QuestionAndAnswer } from './elements';

export const FrequentlyAskedQuestions = () => (
  <TitledSection title="Frequently asked">
    {faqs.map(({ question, answer }, index) => (
      <OwnUpGridContainer variant="legacy" key={index}>
        <OwnUpGridOffset xs={0} md={2} />
        <OwnUpGridItem xs={12} md>
          <QuestionAndAnswer>
            <OwnUpSmallSubheadlineMedium variant="h3">{question}</OwnUpSmallSubheadlineMedium>
            <OwnUpBody variant="body1">{answer}</OwnUpBody>
          </QuestionAndAnswer>
        </OwnUpGridItem>
      </OwnUpGridContainer>
    ))}
  </TitledSection>
);
