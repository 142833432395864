import {
  MOSS_100,
  MOSS_50,
  PropsWithTheme,
  WHITE_100
} from '@rategravity/own-up-component-library';
import styled from 'styled-components';

export const Section = styled.section`
  background-color: ${WHITE_100};
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    display: none;
  }
`;

export const HeadlineWrapper = styled.div`
  background-color: ${MOSS_100};
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5, 0, 5)};
`;

export const ChildrenWrapper = styled.div`
  background-color: ${MOSS_50};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px;
  margin-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(-3)}px;
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5, 3, 5)};
`;
