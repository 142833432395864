import {
  OwnUpExtraSmallHeadlineBook,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridOffset,
  OwnUpGridWrapper
} from '@rategravity/own-up-component-library';
import React from 'react';
import { TitledSectionProps } from '..';
import { ChildrenWrapper, Section } from './elements';

export const TitledSectionDesktop = ({
  title,
  children
}: React.PropsWithChildren<TitledSectionProps>) => {
  const headlineId = `${title.replace(/\s/g, '-')}--section-desktop`;

  return (
    <Section aria-labelledby={headlineId}>
      <OwnUpGridWrapper>
        <OwnUpGridContainer variant="legacy">
          <OwnUpGridItem xs={3}>
            <OwnUpExtraSmallHeadlineBook variant="h2" id={headlineId}>
              {title}
            </OwnUpExtraSmallHeadlineBook>
          </OwnUpGridItem>
          <OwnUpGridOffset xs={1} />
          <OwnUpGridItem xs>
            <ChildrenWrapper>{children}</ChildrenWrapper>
          </OwnUpGridItem>
        </OwnUpGridContainer>
      </OwnUpGridWrapper>
    </Section>
  );
};
