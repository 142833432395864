import { PropsWithTheme } from '@rategravity/own-up-component-library';
import styled from 'styled-components';

export const QuestionAndAnswer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    margin-left: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(-1.5)}px;
  }
`;
