import { StylesProvider } from '@material-ui/core/styles';
import {
  IconLink,
  ownUpDarkTheme,
  OwnUpDetail,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridWrapper,
  OwnUpHeadlineBook,
  OwnUpThemeProvider
} from '@rategravity/own-up-component-library';
import { EmailIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/email';
import { PhoneIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/phone';
import React from 'react';
import { FHEOLogo } from '../../images/icons/fheo';
import {
  FheoAndDeclarationContainer,
  FheoDesktop,
  FheoMobile,
  LinksContainer,
  Section,
  SmallNavLink
} from './elements';

const Fheo = () => (
  <FheoAndDeclarationContainer>
    <FHEOLogo />
    <OwnUpDetail>
      Own Up Power Buyer Services, LLC
      <br />
      50 Milk Street, Boston, MA 02110
    </OwnUpDetail>
  </FheoAndDeclarationContainer>
);

const LinksAndLegal = () => (
  <LinksContainer>
    <SmallNavLink href={`${process.env.GATSBY_WWW}/terms-conditions`}>Terms of use</SmallNavLink>
    <SmallNavLink href={`${process.env.GATSBY_WWW}/privacy`}>Privacy</SmallNavLink>
    <IconLink iconPlacement="left" icon={<PhoneIcon />} link="tel:844-947-2848">
      (844) 947-2848
    </IconLink>
    <IconLink iconPlacement="left" icon={<EmailIcon />} link="mailto:hello@powerbuyer.co">
      hello@powerbuyer.co
    </IconLink>
  </LinksContainer>
);

// StylesProvider needed to handle MUI style injection due to MUI Link
// https://v4.mui.com/guides/interoperability/\#style-library-interoperability
export const Footer = () => (
  <OwnUpThemeProvider theme={ownUpDarkTheme}>
    <StylesProvider injectFirst>
      <Section>
        <OwnUpGridWrapper>
          <OwnUpGridContainer variant="legacy">
            <OwnUpGridItem xs={12} md={6}>
              <OwnUpHeadlineBook variant="h2">Become a Power Buyer</OwnUpHeadlineBook>
              <FheoDesktop>
                <Fheo />
              </FheoDesktop>
            </OwnUpGridItem>
            <OwnUpGridItem xs={12} md={6}>
              <LinksAndLegal />
              <FheoMobile>
                <Fheo />
              </FheoMobile>
            </OwnUpGridItem>
          </OwnUpGridContainer>
        </OwnUpGridWrapper>
      </Section>
    </StylesProvider>
  </OwnUpThemeProvider>
);
