import Link from '@material-ui/core/Link';
import { CHARCOAL_90, PropsWithTheme } from '@rategravity/own-up-component-library';
import styled from 'styled-components';

export const Section = styled.footer`
  background-color: ${CHARCOAL_90};
  color: ${({ theme }: PropsWithTheme<{}>) => theme.palette.text.primary};

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5, 0)};
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10, 0)};
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(1.5)}px;

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3, 0)}px;
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(0)}px;
  }
`;

export const SmallNavLink = styled(Link)`
  color: inherit;
`;

export const FheoAndDeclarationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px;
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5)}px;
  }
`;

export const FheoMobile = styled.div`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    display: none;
  }
`;

export const FheoDesktop = styled.div`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;
